import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatOption, MatSelect } from '@angular/material';
import { Observable } from 'rxjs';
import { SelectData } from 'src/app/library/models/global.models';

@Component({
    selector: 'app-editable-select',
    templateUrl: './editable-select.component.html',
    styleUrls: [
        '../editable.component.css',
        './editable-select.component.css'
    ]
})
export class EditableSelectComponent {

    @Input() data: SelectData[];
    @Input() dataAsync: Observable<SelectData[]>;
    @Input() value: string;

    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<{ text: string, value: string }>();

    @ViewChild('select', { static: false }) selectRef: MatSelect;

    constructor() { }

    update(status: boolean) {
        if (!status) {
            this.cancel.emit();
            return;
        }

        this.save.emit({
            text: (this.selectRef.selected as MatOption).viewValue,
            value: this.selectRef.value
        });
    }
}
