import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { Subject } from 'rxjs';
import { AwsService } from './aws.service';

@Injectable()
export class ConnectionService {

    static subjectLogout = new Subject<void>();

    public username: string;
    public password: string;
    public isConnected = false;
    public error: string;

    private token: any = null;

    constructor(public awsService: AwsService, private router: Router) {
        this.awsService.loadUserSession().subscribe((session: AWSCognito.CognitoUserSession) => {
            this.connect(session.getIdToken().getJwtToken());
        });
    }

    public login(username: string, password: string) {
        const subject = new Subject<void>();

        this.username = username;
        this.password = password;

        setTimeout(() => {
            this.awsService.authenticateUserPool(username, password, (message: string, result: any) => {
                if (message != null) { // error
                    this.error = message;
                    subject.error(this.error);
                } else { // success
                    this.connect(result.getIdToken().getJwtToken());
                    subject.next();
                }
                subject.complete();
            });
        });

        return subject;
    }

    public logout() {
        const subject = new Subject<void>();

        setTimeout(() => {
            ConnectionService.subjectLogout.next();

            this.awsService.logout().subscribe(() => {
                this.isConnected = false;
                this.token = null;

                this.router.navigateByUrl('/').then(e => {
                    subject.next();
                    subject.complete();
                });
            });
        });

        return subject;
    }

    private connect(token) {
        this.token = token;
        this.isConnected = true;
    }

    public getToken() {
        return this.token;
    }
}
