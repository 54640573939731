import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/library/services/api.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';

@Injectable()
export class StorageService extends ApiService {

    public subjectUser = new BehaviorSubject<any>(null);

    private keyUser = 'user';

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);

        ConnectionService.subjectLogout.subscribe(() => {
            this.clear();
        });
    }

    public updateUser() {
        const user: any = JSON.parse(this.get(this.keyUser));
        if (user) {
            this.subjectUser.next(user);
            return;
        }

        if (!this.connectionService.getToken()) {
            return;
        }

        this.httpGET(this.apiUrl + '/user/current', this.getOptions()).subscribe((userData: any[]) => {
            if (userData) {
                this.set(this.keyUser, JSON.stringify(userData[0]));
                this.subjectUser.next(userData[0]);
            }
        });
    }

    public clear() {
        sessionStorage.removeItem(this.keyUser);
    }

    private get(key: string) {
        return sessionStorage.getItem(key);
    }

    private set(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }
}
