import { NgModule } from '@angular/core';
import { LibraryModule } from '../library/library.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TreeComponent } from './components/tree/tree.component';
import { AddNewSubSystemComponent } from './components/add-new-sub-system/add-new-sub-system.component';
import { AddNewSystemComponent } from './components/add-new-system/add-new-system.component';
import { TreeLeafComponent } from './components/tree-leaf/tree-leaf.component';
import { TreeNodeComponent } from './components/tree-node/tree-node.component';
import { TreeNodeLotComponent } from './components/tree-node-lot/tree-node-lot.component';
import { TreeNodeModelSubSystemComponent } from './components/tree-node-model-sub-system/tree-node-model-sub-system.component';
import { TreeNodeModelSystemComponent } from './components/tree-node-model-system/tree-node-model-system.component';
import { TreeNodeSubSystemComponent } from './components/tree-node-sub-system/tree-node-sub-system.component';
import { TreeNodeSystemComponent } from './components/tree-node-system/tree-node-system.component';
import { TreeProjectComponent } from './components/tree-project/tree-project.component';
import { TreeRightDrawerComponent } from './components/tree-right-drawer/tree-right-drawer.component';
import { AddSystemControlComponent } from './components/tree-right-drawer/add-system-control/add-system-control.component';
import { AddSystemControlFileComponent } from './components/tree-right-drawer/add-system-control-file/add-system-control-file.component';
import {
    AddSystemControlVersionComponent
} from './components/tree-right-drawer/add-system-control-version/add-system-control-version.component';
import {
    ShowControlFileVersionComponent
} from './components/tree-right-drawer/show-control-file-version/show-control-file-version.component';
import { ShowControlComponent } from './components/tree-right-drawer/show-control/show-control.component';
import { ControlApiService } from './services/control-api.service';
import { ControlStatusApiService } from './services/control-status-api.service';
import { ControlVersionApiService } from './services/control-version-api.service';
import { FileApiService } from './services/file-api.service';
import { FileVersionApiService } from './services/file-version-api.service';
import { LotApiService } from './services/lot-api.service';
import { ModelControlApiService } from './services/model-control-api.service';
import { ModelSubSystemApiService } from './services/model-sub-system-api.service';
import { ModelSystemApiService } from './services/model-system-api.service';
import { SubSystemApiService } from './services/sub-system-api.service';
import { SystemApiService } from './services/system-api.service';
import { TreeApiService } from './services/tree-api.service';

@NgModule({
    declarations: [
        AddNewSubSystemComponent,
        AddNewSystemComponent,
        AddSystemControlComponent,
        AddSystemControlFileComponent,
        AddSystemControlVersionComponent,
        ShowControlFileVersionComponent,
        ShowControlComponent,
        TreeComponent,
        TreeLeafComponent,
        TreeNodeComponent,
        TreeNodeLotComponent,
        TreeNodeModelSubSystemComponent,
        TreeNodeModelSystemComponent,
        TreeNodeSubSystemComponent,
        TreeNodeSystemComponent,
        TreeProjectComponent,
        TreeRightDrawerComponent
    ],
    imports: [
        LibraryModule,
        SharedModule
    ],
    providers: [
        ControlApiService,
        ControlStatusApiService,
        ControlVersionApiService,
        FileApiService,
        FileVersionApiService,
        LotApiService,
        ModelControlApiService,
        ModelSubSystemApiService,
        ModelSystemApiService,
        SubSystemApiService,
        SystemApiService,
        TreeApiService
    ],
    entryComponents: [
        AddNewSubSystemComponent,
        AddNewSystemComponent,
        AddSystemControlComponent,
        AddSystemControlFileComponent,
        AddSystemControlVersionComponent,
        ShowControlFileVersionComponent
    ],
    exports: []
})
export class TreeModule { }
