import { NgModule } from '@angular/core';
import { LibraryModule } from '../library/library.module';
import { SharedModule } from '../shared/shared.module';
import { ProjectTableComponent } from './components/project-table/project-table.component';
import { ProjectApiService } from './services/project-api.service';

@NgModule({
    declarations: [
        ProjectTableComponent
    ],
    imports: [
        LibraryModule,
        SharedModule
    ],
    providers: [
        ProjectApiService
    ],
    exports: []
})
export class ProjectModule { }
