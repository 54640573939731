import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConnectionService } from 'src/app/shared/services/connection.service';

export interface HttpHeaderObject {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?;
    withCredentials?: boolean;
}

export class ApiService {

    protected apiUrl = 'https://b47619nfuf.execute-api.eu-west-3.amazonaws.com/prod';

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) { }

    protected getOptions(params = null, headerData = {}): HttpHeaderObject {
        const headerConfig = {
            Authorization: this.connectionService.getToken(),
            'Content-Type': 'application/json',
        };

        Object.keys(headerData).forEach(key => headerConfig[key] = headerData[key]);

        const header: HttpHeaderObject = {
            headers: new HttpHeaders(headerConfig)
        };

        if (params) {
            header.params = this.normalizeParams(params);
        }

        return header;
    }

    public errorManagment(error) {
        console.log(error.HttpErrorResponse);
        // this.connectionService.logout();
    }

    public httpGET(url, options): Observable<any> {
        return this.http.get(url, options).pipe(
            tap(null, this.errorManagment)
        );
    }

    public httpPOST(url, data, options): Observable<any> {
        return this.http.post(url, data, options).pipe(
            tap(null, this.errorManagment)
        );
    }

    private normalizeParams(params) {
        const newParams: any = {};

        Object.keys(params).forEach(key => {
            if (typeof params[key] === 'object') {
                newParams[key] = JSON.stringify(params[key]);
            } else {
                newParams[key] = params[key];
            }
        });
        return newParams;
    }
}
