import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TableDataType } from 'src/app/library/models/global.models';
import { ApiService } from 'src/app/library/services/api.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';

export class TableApiService extends ApiService {

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);
    }

    public getOptionsForTable(sortColumn, sortOrder = 'asc', pageNumber = 0, pageSize = 5, search = '', filters = {}) {
        const params: any = { sort_column: sortColumn, sort_order: sortOrder, offset: pageNumber * pageSize, limit: pageSize };

        if (search !== '' && search !== null) {
            params.search = search;
        }

        Object.keys(filters).forEach(k => {
            params[k] = filters[k];
        });

        return this.getOptions(params);
    }

    public httpGETTable(url, sortColumn, sortOrder = 'asc', pageNumber = 0, pageSize = 5, search = '', filters = {})
        : Observable<TableDataType<any>> {
        return this.httpGET(url, this.getOptionsForTable(sortColumn, sortOrder, pageNumber, pageSize, search, filters));
    }

    public getTableData(sortColumn, sortOrder = 'asc', pageNumber = 0, pageSize = 5, search = '', filters = {})
        : Observable<TableDataType<any>> {
        throw Error('Method getTableData not declare');
    }

    public edit(data): Observable<any> {
        throw Error('Method edit not declare');
    }

    public add(data): Observable<any> {
        throw Error('Method add not declare');
    }

    public get(data): Observable<any> {
        throw Error('Method get not declare');
    }
}
