import { Component, Input, OnInit } from '@angular/core';
import { LotType } from 'src/app/library/models/data.models';

@Component({
    selector: 'app-tree-node-lot',
    templateUrl: './tree-node-lot.component.html',
    styleUrls: ['../../styles/tree.global.css', './tree-node-lot.component.css']
})
export class TreeNodeLotComponent implements OnInit {

    public treeId: string;

    @Input() lot: LotType;
    @Input() project: number;
    @Input() treeParents: string[];

    constructor() { }

    ngOnInit() {
        this.treeId = this.treeParents.join('-') + '-' + this.lot.id;
    }

    getChildrenParent() {
        const tmpArray = this.treeParents.slice(0);
        tmpArray.push(this.treeId);

        return tmpArray;
    }
}
