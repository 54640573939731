import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SubSystemType } from 'src/app/library/models/data.models';
import { SelectData } from 'src/app/library/models/global.models';
import { SubSystemApiService } from '../../services/sub-system-api.service';
import { ModelSubSystemApiService } from '../../services/model-sub-system-api.service';

@Component({
    selector: 'app-tree-add-new-sub-system',
    templateUrl: './add-new-sub-system.component.html',
    styleUrls: ['../../../shared//styles/dialog.component.css', './add-new-sub-system.component.css']
})
export class AddNewSubSystemComponent {

    public error = null;
    public modelSubSystems: SelectData[] = [];
    public newSubSystemForm = new FormGroup({
        modelSubSystem: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
    });
    public systemId: number;

    constructor(
        public dialogRef: MatDialogRef<AddNewSubSystemComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { systemId: number },
        private modelSubSystem: ModelSubSystemApiService,
        private subSystemApiService: SubSystemApiService
    ) {
        this.modelSubSystem
            .getList({ system: data.systemId })
            .subscribe((modelSubSystems: SelectData[]) => {
                this.modelSubSystems = modelSubSystems;

                if (modelSubSystems.length <= 0) {
                    this.error = 'Aucun modèle de sous-système disponible pour le modèle de ce système.';
                }
            });
        this.systemId = data.systemId;
    }

    public save() {
        if (!this.newSubSystemForm.value.name) {
            this.error = 'Le nom ne peut pas être vide.';
            return;
        }

        if (!this.newSubSystemForm.value.modelSubSystem) {
            this.error = 'Le modèle ne peut pas être vide.';
            return;
        }

        const data = {
            modelSubSystem: this.newSubSystemForm.value.modelSubSystem,
            name: this.newSubSystemForm.value.name,
            system: this.systemId
        };

        this.subSystemApiService
            .add(data)
            .subscribe((system: SubSystemType) => {
                this.dialogRef.close(system);
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
