import { Component } from '@angular/core';
import { ConnectionService, } from 'src/app/shared/services/connection.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent {

    constructor(
        public connectionService: ConnectionService
    ) { }
}
