import { Component } from '@angular/core';
import { SubSystemType, SystemType } from 'src/app/library/models/data.models';
import { TreeComponent } from '../tree/tree.component';
import { TreeRightDrawerSubjectParameter } from '../../models/tree.models';
import { SubSystemApiService } from '../../services/sub-system-api.service';
import { SystemApiService } from '../../services/system-api.service';

@Component({
    selector: 'app-tree-right-drawer',
    templateUrl: './tree-right-drawer.component.html',
    styleUrls: ['./tree-right-drawer.component.css']
})

export class TreeRightDrawerComponent {

    public error: string = null;
    public system: SystemType = null;
    public subSystem: SubSystemType = null;

    private systemRef: SystemType = null;
    private subSystemRef: SubSystemType = null;

    constructor(private subSystemApiService: SubSystemApiService, private systemApiService: SystemApiService) {
        TreeComponent.subjectOpenRightDrawer.subscribe((element: TreeRightDrawerSubjectParameter) => {
            this.system = null;
            this.subSystem = null;
            this.systemRef = element.system;
            this.subSystemRef = element.subSystemType;

            if (element.system) {
                this.initSystem(element.system);
            } else if (element.subSystemType) {
                this.initSubSystem(element.subSystemType);
            }
        });
    }

    private initSystem(system: SystemType) {
        if (!system || !system.id) {
            this.error = 'Identifiant système vide.';
            return;
        }

        this.systemApiService.get(system.id).subscribe(data => {
            if (!data[0]) {
                this.error = 'Aucun système n\'a été trouvé.';
                return;
            }

            this.system = data[0];
        });
    }

    private initSubSystem(subSystem: SubSystemType) {
        if (!subSystem || !subSystem.id) {
            this.error = 'Identifiant sous-système vide.';
            return;
        }

        this.subSystemApiService.get(subSystem.id).subscribe(data => {
            if (!data[0]) {
                this.error = 'Aucun sous-système n\'a été trouvé.';
                return;
            }

            this.subSystem = data[0];
        });
    }

    systemNameChanged(data) {
        this.systemRef[data.name] = data.value;
    }

    subSystemNameChanged(data) {
        this.subSystemRef[data.name] = data.value;
    }
}
