import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModelSystemType } from 'src/app/library/models/data.models';
import { AddNewSystemComponent } from '../add-new-system/add-new-system.component';

@Component({
    selector: 'app-tree-node-model-system',
    templateUrl: './tree-node-model-system.component.html',
    styleUrls: ['../../styles/tree.global.css', './tree-node-model-system.component.css']
})
export class TreeNodeModelSystemComponent implements OnInit {

    public treeId: string;

    @Input() lot: number;
    @Input() modelSystem: ModelSystemType;
    @Input() project: number;
    @Input() treeParents: string[];

    constructor(public dialog: MatDialog) { }

    ngOnInit() {
        this.treeId = this.treeParents.join('-') + '-' + this.modelSystem.id;
    }

    getChildrenParent() {
        const tmpArray = this.treeParents.slice(0);
        tmpArray.push(this.treeId);

        return tmpArray;
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(AddNewSystemComponent, {
            data: { lotId: this.lot, modelSystemId: this.modelSystem.id, projectId: this.project },
            disableClose: true,
            width: '800px',
        });

        dialogRef.afterClosed().subscribe(newSystem => {
            if (!newSystem) {
                return;
            }

            this.modelSystem.systems.push(newSystem[0]);
        });
    }
}
