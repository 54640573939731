import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { LotType, ModelSystemType, SystemType } from 'src/app/library/models/data.models';
import { SelectData } from 'src/app/library/models/global.models';
import { ProjectApiService } from 'src/app/project/services/project-api.service';
import { TreeApiService } from 'src/app/tree/services/tree-api.service';
import { ControlStatusApiService } from '../../services/control-status-api.service';
import { AddNewSystemComponent } from '../add-new-system/add-new-system.component';
import { TreeComponent } from '../tree/tree.component';

@Component({
    selector: 'app-tree-project',
    templateUrl: './tree-project.component.html',
    styleUrls: ['../../styles/tree.global.css', './tree-project.component.css']
})
export class TreeProjectComponent implements OnInit {

    public config;
    public controlStatuses: Observable<SelectData[]>;
    public filters: any = {};
    public lots: LotType[];
    public showFilter = false;
    public treeId = 'root';
    public treeParents = [this.treeId];

    public project;

    constructor(
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private controlStatusApiService: ControlStatusApiService,
        private projectService: ProjectApiService,
        private treeService: TreeApiService
    ) {
        this.controlStatuses = this.controlStatusApiService.getList();
    }

    ngOnInit() {
        this.project = this.route.snapshot.paramMap.get('id');

        TreeComponent.subjectRefreshTree.subscribe(() => {
            this.refreshLots();
        });

        this.refreshLots();

        this.config = {
            fileName: 'controles.xls',
            sheets: [
                {
                    columns: [
                        { header: 'Projet', key: 'project', width: 25 },
                        { header: 'Lot', key: 'lot', width: 25, style: { alignment: { wrapText: true } } },
                        { header: 'Modèle de système', key: 'model_system', width: 25 },
                        { header: 'Système', key: 'system', width: 25 },
                        { header: 'Modèle de sous-système', key: 'model_sub_system', width: 25 },
                        { header: 'Sous-système', key: 'sub_system', width: 25 },
                        { header: 'Contrôle', key: 'control', width: 25 },
                        { header: 'Entreprise', key: 'company', width: 25 },
                        { header: 'Version', key: 'version', width: 25 },
                        { header: 'Commentaire', key: 'comment', width: 25 },
                        { header: 'Etat', key: 'status', width: 25 },
                    ],
                    data: {
                        observable: this.treeService.downloadCSV({ project: this.project })
                    },
                    name: 'Contrôles'
                }
            ],
            type: 'xls'
        };
    }

    displayTree(event) {
        TreeComponent.displayTree(event, this.treeId);
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(AddNewSystemComponent, {
            data: { projectId: this.project },
            disableClose: true,
            width: '800px',
        });

        dialogRef.afterClosed().subscribe((newSystems: SystemType[]) => {
            if (!newSystems) {
                return;
            }
            const newSystem = newSystems[0];
            let insert = false;
            this.lots.forEach((lot: LotType) => {
                if (lot.id !== newSystem.modelSystem.lot.id) {
                    return;
                }

                lot.modelSystems.forEach((modelSystem: ModelSystemType) => {
                    if (modelSystem.id !== newSystem.modelSystem.id) {
                        return;
                    }

                    modelSystem.systems.push(newSystem);
                    insert = true;
                });
            });

            if (!insert) {
                this.refreshLots();
            }
        });
    }

    refreshLots() {
        this.lots = null;
        this.treeService.getTreeByProject(this.project, this.filters).subscribe((lots: LotType[]) => {
            this.lots = lots;
        });
    }

    filterControlStatus(event) {
        if (event.value.length > 0) {
            this.filters.controlStatus = event.value;
        } else {
            delete this.filters.controlStatus;
        }
        this.refreshLots();
    }

    export() {
        this.projectService.exportControlTodoSystemless(this.project);
        this.projectService.exportControlTodoSystem(this.project);
        this.projectService.exportControlDone(this.project);
    }
}
