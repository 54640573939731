import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';

@Component({
    selector: 'app-connection',
    templateUrl: './connection.component.html',
    styleUrls: ['./connection.component.css']
})
export class ConnectionComponent {

    public loading = false;
    public connectionForm = new FormGroup({
        username: new FormControl(null, [Validators.required]),
        password: new FormControl(null, [Validators.required])
    });

    constructor(private connectionService: ConnectionService) { }

    public login() {
        this.loading = true;
        this.connectionService
            .login(this.connectionForm.value.username, this.connectionForm.value.password)
            .subscribe(() => {
                this.loading = false;
            });
    }
}
