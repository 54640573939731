import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyApiService } from '../../../shared/services/company-api.service';

@Component({
    selector: 'app-company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['../../../shared/styles/form.component.css', './company-form.component.css']
})
export class CompanyFormComponent {

    public error = '';
    public form = new FormGroup({
        name: new FormControl(null, Validators.required)
    });

    constructor(private router: Router, private companyApiService: CompanyApiService) { }

    save() {
        const data = this.form.value;

        this.companyApiService.add(data).subscribe(
            res => {
                if (!res || !res.id) {
                    this.error = 'error';
                    return;
                }

                this.router.navigateByUrl('/company/' + res.id);
            }
        );
    }
}
