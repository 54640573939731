import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-editable-checkbox',
    templateUrl: './editable-checkbox.component.html',
    styleUrls: [
        '../editable.component.css',
        './editable-checkbox.component.css'
    ]
})
export class EditableCheckboxComponent {

    private val: boolean = null;

    @Input() value: boolean;

    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<{ text: any, value: any }>();

    constructor() { }

    update(status: boolean) {
        if (!status || (this.val !== null && this.val === this.value)) {
            this.cancel.emit();
            return;
        }

        this.save.emit({
            text: this.val,
            value: this.val
        });
    }

    updateValue(event) {
        this.val = event.checked;
    }
}
