import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { CompanyModule } from './company/company.module';
import { CompanyFormComponent } from './company/components/company-form/company-form.component';
import { CompanyShowComponent } from './company/components/company-show/company-show.component';
import { CompanyTableComponent } from './company/components/company-table/company-table.component';
import { CoreModule } from './core/core.module';
import { HomeComponent } from './core/components/home/home.component';
import { ProjectModule } from './project/project.module';
import { ProjectTableComponent } from './project/components/project-table/project-table.component';
import { TreeModule } from './tree/tree.module';
import { TreeProjectComponent } from './tree/components/tree-project/tree-project.component';

const routes: Routes = [
    { path: '', component: HomeComponent },

    { path: 'company/add', component: CompanyFormComponent },
    { path: 'company/list', component: CompanyTableComponent },
    { path: 'company/:id', component: CompanyShowComponent },
    { path: 'company/:id/edit', component: CompanyFormComponent },

    { path: 'project/list', component: ProjectTableComponent },

    { path: 'tree/project/:id', component: TreeProjectComponent },
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        RouterModule.forRoot(routes),
        AuthenticationModule,
        CompanyModule,
        CoreModule,
        ProjectModule,
        SharedModule,
        TreeModule
    ],
    exports: [
        RouterModule
    ],
    providers: [],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
