import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LibraryModule } from '../library/library.module';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
    declarations: [
        HomeComponent,
        MenuComponent
    ],
    imports: [
        LibraryModule,
        SharedModule
    ],
    providers: [],
    exports: [
        MenuComponent
    ]
})
export class CoreModule { }
