import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { switchMap } from 'rxjs/operators';
import { FileType, FileVersionType } from 'src/app/library/models/data.models';
import { UploadFileApiService } from 'src/app/library/services/upload-file-api.service';
import { FileApiService } from 'src/app/tree/services/file-api.service';
import { FileVersionApiService } from 'src/app/tree/services/file-version-api.service';

@Component({
    selector: 'app-tree-show-control-file-version',
    templateUrl: './show-control-file-version.component.html',
    styleUrls: ['../../../../shared/styles/dialog.component.css', './show-control-file-version.component.css']
})
export class ShowControlFileVersionComponent {

    public file: FileType;

    private controlVersionId: number;

    @ViewChild('uploadFile', { static: false }) uploadFile;

    constructor(
        public uploadFileApiService: UploadFileApiService,
        public dialogRef: MatDialogRef<ShowControlFileVersionComponent>,
        private fileApiService: FileApiService,
        private fileVersionApiService: FileVersionApiService,
        @Inject(MAT_DIALOG_DATA) public data: { file: FileType, controlVersionId: number }
    ) {
        this.file = data.file;
        this.controlVersionId = data.controlVersionId;
    }

    download(fileVersion: FileVersionType) {
        this.uploadFileApiService.downloadFileS3(fileVersion.name);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    addFileVersion() {
        this.fileApiService.getFileName(this.controlVersionId, this.file.id, this.file.name)
            .pipe(
                switchMap(
                    (fileName: string) => this.uploadFile.uploadFiles(name => {
                        const ext = /(?:\.([^.]+))?$/.exec(name)[1];
                        return fileName + '.' + ext;
                    }),
                    (fileName, file) => file
                ),
                switchMap(
                    (fileData: { file: File, fileName: string }) => {
                        const data = {
                            file: this.file.id,
                            name: fileData.fileName,
                        };

                        return this.fileVersionApiService.add(data);
                    },
                    (i, o) => o
                )
            ).subscribe((fileVersion: FileVersionType[]) => {
                this.file.fileVersions.push(fileVersion[0]);
                this.uploadFile.resetFiles();
            });
    }
}
