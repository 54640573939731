import { NgModule } from '@angular/core';
import { LibraryModule } from '../library/library.module';
import { SharedModule } from '../shared/shared.module';
import { CompanyFormComponent } from './components/company-form/company-form.component';
import { CompanyShowComponent } from './components/company-show/company-show.component';
import { CompanyTableComponent } from './components/company-table/company-table.component';

@NgModule({
    declarations: [
        CompanyFormComponent,
        CompanyShowComponent,
        CompanyTableComponent
    ],
    imports: [
        LibraryModule,
        SharedModule
    ],
    providers: [],
    exports: []
})
export class CompanyModule { }
