import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-editable-input-text',
    templateUrl: './editable-input-text.component.html',
    styleUrls: [
        '../editable.component.css',
        './editable-input-text.component.css'
    ]
})
export class EditableInputTextComponent implements OnInit {

    @Input() value: string;

    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<{ text: string, value: string }>();

    @ViewChild('input', { static: false }) inputRef: ElementRef;

    constructor() { }

    ngOnInit() {
        requestAnimationFrame(() => {
            this.inputRef.nativeElement.focus();
        });
    }

    update(status: boolean) {
        if (!status) {
            this.cancel.emit();
            return;
        }

        this.save.emit({
            text: this.inputRef.nativeElement.value,
            value: this.inputRef.nativeElement.value
        });
    }
}
