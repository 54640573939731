import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SystemType, ModelSubSystemType } from 'src/app/library/models/data.models';
import { TreeComponent } from '../tree/tree.component';
import { AddNewSubSystemComponent } from '../add-new-sub-system/add-new-sub-system.component';

@Component({
    selector: 'app-tree-node-system',
    templateUrl: './tree-node-system.component.html',
    styleUrls: ['../../styles/tree.global.css', './tree-node-system.component.css']
})
export class TreeNodeSystemComponent implements OnInit {

    public treeId: string;

    @Input() system: SystemType;
    @Input() treeParents: string[];

    constructor(public dialog: MatDialog) { }

    ngOnInit() {
        this.treeId = this.treeParents.join('-') + '-' + this.system.id;
    }

    openDrawer() {
        TreeComponent.subjectOpenRightDrawer.next({ system: this.system });
    }

    getChildrenParent() {
        const tmpArray = this.treeParents.slice(0);
        tmpArray.push(this.treeId);

        return tmpArray;
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(AddNewSubSystemComponent, {
            data: { systemId: this.system.id },
            disableClose: true,
            width: '800px',
        });

        dialogRef.afterClosed().subscribe(newSubSystem => {
            if (!newSubSystem) {
                return;
            }

            let insert = false;
            this.system.modelSubSystems.forEach((modelSubSystem: ModelSubSystemType) => {
                if (modelSubSystem.id === newSubSystem[0].modelSubSystem.id) {
                    modelSubSystem.subSystems.push(newSubSystem[0]);
                    insert = true;
                }
            });

            if (!insert) {
                TreeComponent.subjectRefreshTree.next();
            }
        });
    }
}
