import { Component } from '@angular/core';

@Component({
    selector: 'app-tree-leaf',
    templateUrl: './tree-leaf.component.html',
    styleUrls: ['../../styles/tree.global.css', './tree-leaf.component.css']
})
export class TreeLeafComponent {

    constructor() { }
}
