import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemType } from '../../library/models/data.models';
import { ApiService } from '../../library/services/api.service';
import { ConnectionService } from '../../shared/services/connection.service';

@Injectable()
export class SystemApiService extends ApiService {

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);
    }

    public get(id: number): Observable<SystemType[]> {
        return this.httpGET(this.apiUrl + '/system', this.getOptions({ system: id }));
    }

    public add(data): Observable<any> {
        return this.httpPOST(this.apiUrl + '/system/add', data, this.getOptions());
    }
}
