import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyType } from 'src/app/library/models/data.models';
import { SelectData, TableDataType } from 'src/app/library/models/global.models';
import { TableApiService } from 'src/app/library/services/table-api.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';

@Injectable()
export class CompanyApiService extends TableApiService {

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);
    }

    public getTableData(sortColumn, sortOrder = 'asc', pageNumber = 0, pageSize = 5, search = '')
        : Observable<TableDataType<CompanyType>> {
        return this.httpGETTable(this.apiUrl + '/company/table', sortColumn, sortOrder, pageNumber, pageSize, search);
    }

    public get(id: number): Observable<CompanyType[]> {
        return this.httpGET(this.apiUrl + '/company', this.getOptions({ company: id }));
    }

    public add(data): Observable<any> {
        return this.httpPOST(this.apiUrl + '/company/add', data, this.getOptions());
    }

    public edit(data): Observable<any> {
        return this.httpPOST(this.apiUrl + '/company/edit', data, this.getOptions());
    }

    public getList(): Observable<SelectData[]> {
        return this.httpGET(this.apiUrl + '/company/list', this.getOptions());
    }
}
