import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenerateFileNameType } from 'src/app/library/models/global.models';
import { ApiService } from 'src/app/library/services/api.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';

@Injectable()
export class FileApiService extends ApiService {

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);
    }

    public add(data): Observable<any> {
        return this.httpPOST(this.apiUrl + '/file/add', data, this.getOptions());
    }

    public getFileName(controlVersion: number, file: number = null, fileName: string = null) {
        const data: any = { controlVersion };
        if (file) {
            data.file = file;
        }
        const options = this.getOptions(data);

        return (this.http.get(this.apiUrl + '/file/get-name', options) as Observable<GenerateFileNameType>).pipe(
            map((r: GenerateFileNameType) => {
                let name = r.base + '_';

                if (r.file) {
                    name = name + r.file[0].name + '_' + r.file[0].version;
                } else {
                    name = name + fileName + '_' + 0;
                }

                return name;
            })
        );
    }
}
