import { Component, Input, OnInit } from '@angular/core';
import { SubSystemType } from 'src/app/library/models/data.models';
import { TreeComponent } from '../tree/tree.component';

@Component({
    selector: 'app-tree-node-sub-system',
    templateUrl: './tree-node-sub-system.component.html',
    styleUrls: ['../../styles/tree.global.css', './tree-node-sub-system.component.css']
})
export class TreeNodeSubSystemComponent implements OnInit {

    public treeId: string;

    @Input() subSystem: SubSystemType;
    @Input() treeParents: string[];

    constructor() { }

    ngOnInit() {
        this.treeId = this.treeParents.join('-') + '-' + this.subSystem.id;
    }

    openDrawer() {
        TreeComponent.subjectOpenRightDrawer.next({ subSystemType: this.subSystem });
    }
}
