import { Injectable } from '@angular/core';

@Injectable()
export class MediaService {

    static downloadCSV(header: string[], csvContent: any[], filename: string) {
        const content = header.join(';') + '\r\n' + csvContent.map(d => header.map(c => d[c]).join(';')).join('\r\n');

        const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${content}`);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${filename}.csv`);
        link.click();
        link.remove();
    }

    constructor() { }

}
