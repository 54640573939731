import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as AWS from 'aws-sdk';
import * as AWSCognito from 'amazon-cognito-identity-js';

@Injectable()
export class AwsService {

    public userData: any;

    /************ RESOURCE IDENTIFIERS *************/

    private poolData = {
        UserPoolId: 'eu-central-1_pI5NjCP2y', // CognitoUserPool
        ClientId: '1ssp9hsaud204dst3q01hff3k8', // CognitoUserPoolClient
        Paranoia: 7
    };
    private identityPool = 'eu-central-1:908681a8-2b91-4884-8148-85cec43dcbe6'; // CognitoIdentityPool
    private apiURL = 'https://8im3avbfxk.execute-api.eu-central-1.amazonaws.com/demo';  // ApiUrl
    private region = 'eu-central-1'; // Region Matching CognitoUserPool region

    /*********************************************/

    constructor(private http: HttpClient) {
        AWS.config.update({
            region: this.region,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: ''
            })
        });
        AWS.config.region = this.region;
        AWS.config.update({ accessKeyId: 'null', secretAccessKey: 'null' });
    }

    public loadUserSession(): Observable<AWSCognito.CognitoUserSession> {
        const subj = new Subject<AWSCognito.CognitoUserSession>();

        const cognitoUser = this.getCurrentCognitoUser();

        setTimeout(() => {
            if (cognitoUser != null) {
                cognitoUser.getSession((err, session) => {
                    if (err) {
                        console.log('error during getting user', err);
                        subj.error('error during getting user' + err);
                    }
                    if (session.isValid()) {
                        subj.next(session);
                        subj.complete();
                    }
                    subj.error('session no more valid');

                });
            }
            subj.error('no user');
        });

        return subj;
    }

    public logout() {
        const subj = new Subject<void>();
        const cognitoUser = this.getCurrentCognitoUser();

        setTimeout(() => {
            if (cognitoUser != null) {
                cognitoUser.signOut();
            }
            subj.next();
            subj.complete();
        });
        return subj;
    }

    authenticateUserPool(user, password, callback) {
        const authenticationData = {
            Username: user,
            Password: password,
        };
        const authenticationDetails = new AWSCognito.AuthenticationDetails(authenticationData);
        const userPool = this.getUserPool();
        const userData = {
            Username: user,
            Pool: userPool
        };
        const cognitoUser = new AWSCognito.CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: result => {
                const cognitoGetUser = userPool.getCurrentUser();
                callback(null, result);
                if (cognitoGetUser != null) {
                    cognitoGetUser.getSession((err, result1) => {
                        if (result1) {
                            console.log('Authenticated to Cognito User Pools!');
                        }
                    });
                }
            },
            onFailure: err => {
                callback(err, null);
            }
        });
    }

    getInfoApiUserPools(token): Observable<any> {
        const httpOptions = {
            headers: this.getAccessHeaders(token)
        };

        return this.http.get(this.apiURL + '/cup', httpOptions).pipe(
            map((res: any) => res.Items[0]),
            catchError(this._serverError)
        );
    }

    private getAccessHeaders(token) {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token
        });
    }

    private _serverError(err: any) {
        console.log('sever error:', JSON.stringify(err));  // debug
        if (err.status === 0) {
            return Observable.throw(err.json().error || 'UNAUTHORIZED!!!');
        }
        if (err instanceof HttpResponse) {
            return Observable.throw(err || 'Backend Server Error');
        }
        // return Observable.throw(err || 'Backend Server Error');
    }

    private getUserPool() {
        return new AWSCognito.CognitoUserPool(this.poolData);
    }

    private getCurrentCognitoUser() {
        const userPool = this.getUserPool();
        return userPool.getCurrentUser();
    }
}
