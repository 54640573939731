import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService, HttpHeaderObject } from './api.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';

@Injectable()
export class UploadFileApiService extends ApiService {

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);
    }

    public generateS3UploadUrl(fileName: string) {
        const options = this.getOptions();
        const params = { filename: fileName };

        return this.http.post(this.apiUrl + '/file/generate-upload-url-s3', params, options) as Observable<any>;
    }

    public uploadFileS3(file: File, url: string) {
        const options = this.uploadFileHeader(file.type);

        return this.http.put(url, file, options) as Observable<any>;
    }

    public getFileS3(name) {
        const options = this.getOptions(null);

        options.responseType = 'blob';

        options.headers = (options.headers as HttpHeaders).delete('Accept').append('Accept', '*/*');

        return this.http.get(this.apiUrl + '/file/' + name, options);
    }

    public downloadFileS3(name) {
        this.getFileS3(name).subscribe((res: any) => {
            console.log(res);
            // const blob = new Blob([res], { type: 'image/png' });
            const url = URL.createObjectURL(res);
            const a = document.createElement('a');
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        });
    }

    private uploadFileHeader(fileType: string) {
        const header: HttpHeaderObject = {
            headers: new HttpHeaders({
                'Content-Type': fileType
            })
        };

        return header;
    }
}
