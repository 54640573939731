import { Component, ElementRef, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { Subscription } from 'rxjs';
import { TreeToggleNodeData } from 'src/app/library/models/data.models';
import { TreeComponent } from '../tree/tree.component';

@Component({
    selector: 'app-tree-node',
    templateUrl: './tree-node.component.html',
    styleUrls: ['../../styles/tree.global.css', './tree-node.component.css']
})
export class TreeNodeComponent implements OnInit, OnDestroy {

    private subToggleNode: Subscription;

    @Input() open = true;
    @Input() treeId: string;
    @Input() treeParents: string[];

    @ViewChild('childrens', { static: false }) childrens: ElementRef;
    @ViewChild(MatMenuTrigger, { static: false }) triggerMenu: MatMenuTrigger;

    constructor() { }

    ngOnInit() {
        this.subToggleNode = TreeComponent.subjectToggleNode.subscribe((data: TreeToggleNodeData) => {
            this.listener(data);
        });
    }

    ngOnDestroy() {
        this.subToggleNode.unsubscribe();
    }

    collapse() {
        const self = this;
        const height = this.getHeight();

        if ('0px' === this.childrens.nativeElement.style.height) {
            // avoid expanding when no transition will be done
            this.open = false;
            return;
        }

        // temporarily disable all css transitions
        const elementTransition = this.childrens.nativeElement.style.transition;
        this.childrens.nativeElement.style.transition = '';

        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we
        // aren't transitioning out of 'auto'
        requestAnimationFrame(() => {
            self.childrens.nativeElement.style.height = height + 'px';
            self.childrens.nativeElement.style.transition = elementTransition;

            // on the next frame (as soon as the previous style change has taken effect),
            // have the element transition to height: 0
            requestAnimationFrame(() => {
                self.childrens.nativeElement.style.height = 0 + 'px';
                self.open = false;
            });
        });
    }

    expand() {
        const self = this;
        const height = this.getHeight();

        if (height + 'px' === this.childrens.nativeElement.style.height ||
            '' === this.childrens.nativeElement.style.height) {
            // avoid expanding when no transition will be done
            this.open = true;
            return;
        }

        // have the element transition to the height of its inner content
        this.childrens.nativeElement.style.height = height + 'px';

        // when the next css transition finishes (which should be the one we just triggered)
        this.childrens.nativeElement.addEventListener('transitionend', function endTransition(e) {
            // remove this event listener so it only gets triggered once
            self.childrens.nativeElement.removeEventListener('transitionend', endTransition);
            // remove "height" from the element's inline styles, so it can return to its initial value
            self.childrens.nativeElement.style.height = null;
            self.open = true;
        });
    }

    openMenu(event) {
        event.preventDefault(); // Suppress the browser's context menu
        this.triggerMenu.openMenu();
    }

    toggle(status: boolean) {
        TreeComponent.subjectToggleNode.next({
            depth: -1,
            forceClose: false,
            state: status,
            target: this.treeId
        });
    }

    private getHeight() {
        // get the height of the element's inner content, regardless of its actual size
        return this.childrens.nativeElement.scrollHeight;
    }

    private listener(data: TreeToggleNodeData) {
        const self = this;
        let depth = this.treeParents.length;
        let method = null;

        // Vérification du niveau de l'élément, il doit être inférieur à celui donné
        // Vérification des parents, target doit être un des parents ou l'élement lui même
        if ((data.depth > depth || data.depth === -1) &&
            (this.treeParents.indexOf(data.target) >= 0 || data.target === this.treeId)) {
            if (data.state) {
                method = 'expand';
            } else {
                method = 'collapse';
            }
        } else if (data.forceClose) {
            method = 'collapse';
        }

        if (method !== null) {
            // Si on ferme, on inverse les niveaux (3 étant le niveau maximum de l'arbre)
            if (method === 'collapse') {
                depth = 3 - depth;
            }
            // On soustrait 1 pour réduire à 0 le niveau 1 (transmetteur)
            // Puis on multiplie par 3 pour l'étape suivante.
            depth = (depth - 1) * 3;

            // Fonction récursive, qui boucle sur un appel de requestAnimationFrame
            // A chaque itération, on réduit le niveau par 1, arriver à 0, on appelle la méthode (expand/collapse)
            // La boucle permet de temporisé l'appel selon le niveau des éléments pour un effet "cascade"
            //      Exemple : En ouverture
            //      Transmetteur (1) aura depth = 0 donc il n'aura que l'appel initial de requestAnimationFrame
            //      Capteur (2) aura depth = 3 donc il y aura 4 appel de requestAnimationFrame (1 initial + 3 itérations)
            //      Point de mesure (3) aura depth = 6 donc il y aura 7 appel de requestAnimationFrame (1 initial + 6 itérations)
            //      En fermeture, on inverse depth pour inverser l'ordre
            const callback = () => {
                requestAnimationFrame(() => {
                    if (depth <= 0) {
                        self[method]();
                    } else {
                        --depth;
                        callback();
                    }
                });
            };
            callback();
        }
    }
}
