import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { switchMap } from 'rxjs/operators';
import { ControlVersionType, FileType } from 'src/app/library/models/data.models';
import { FileApiService } from 'src/app/tree/services/file-api.service';

@Component({
    selector: 'app-tree-add-system-control-file',
    templateUrl: './add-system-control-file.component.html',
    styleUrls: ['../../../../shared/styles/dialog.component.css', './add-system-control-file.component.css']
})
export class AddSystemControlFileComponent {

    public controlVersion: ControlVersionType;
    public error: string = null;
    public newFile = new FormGroup({
        name: new FormControl('', Validators.required)
    });

    @ViewChild('uploadFile', { static: false }) uploadFile;

    constructor(
        public dialogRef: MatDialogRef<AddSystemControlFileComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { controlVersion: ControlVersionType },
        private fileApiService: FileApiService,
    ) {
        this.controlVersion = data.controlVersion;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save() {
        this.fileApiService.getFileName(this.controlVersion.id, null, this.newFile.value.name)
            .pipe(
                switchMap(
                    (fileName: string) => this.uploadFile.uploadFiles(name => {
                        const ext = /(?:\.([^.]+))?$/.exec(name)[1];
                        return fileName + '.' + ext;
                    }),
                    (fileName, file) => file
                ),
                switchMap(
                    (fileData: { file: File, fileName: string }) => {
                        const data = {
                            controlVersion: this.controlVersion.id,
                            fileName: fileData.fileName,
                            name: this.newFile.value.name
                        };

                        return this.fileApiService.add(data);
                    },
                    (i, o) => o
                )
            ).subscribe((file: FileType[]) => {
                this.dialogRef.close(file[0]);
            });
    }
}
