import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ControlType,
    ControlStatusType,
    ControlVersionType,
    FileType,
    SubSystemType,
    SystemType
} from 'src/app/library/models/data.models';
import { SelectData } from 'src/app/library/models/global.models';
import { UploadFileApiService } from 'src/app/library/services/upload-file-api.service';
import { ControlStatusApiService } from 'src/app/tree/services/control-status-api.service';
import { AddSystemControlComponent } from '../add-system-control/add-system-control.component';
import { AddSystemControlFileComponent } from '../add-system-control-file/add-system-control-file.component';
import { AddSystemControlVersionComponent } from '../add-system-control-version/add-system-control-version.component';
import { ShowControlFileVersionComponent } from '../show-control-file-version/show-control-file-version.component';

@Component({
    selector: 'app-show-control',
    templateUrl: './show-control.component.html',
    styleUrls: ['./show-control.component.css']
})

export class ShowControlComponent {

    public statusList: Observable<SelectData[]>;

    @Input() controls: ControlType[];
    @Input() subSystem: SubSystemType;
    @Input() system: SystemType;

    constructor(
        public uploadFileApiService: UploadFileApiService,
        public dialog: MatDialog,
        private controlStatusApiService: ControlStatusApiService) {
        this.statusList = this.controlStatusApiService.getList();
    }

    openFile(file: FileType, controlVersionId: number) {
        this.dialog.open(ShowControlFileVersionComponent, {
            data: { file, controlVersionId },
            disableClose: true,
            width: '800px',
        });
    }

    addControl() {
        const dialogRef = this.dialog.open(AddSystemControlComponent, {
            data: { system: this.system, subSystem: this.subSystem },
            disableClose: true,
            width: '800px',
        });

        dialogRef.afterClosed().subscribe(newControl => {
            if (!newControl) {
                return;
            }

            this.controls.push(newControl);
        });
    }

    addControlVersion(control: ControlType) {
        const dialogRef = this.dialog.open(AddSystemControlVersionComponent, {
            data: { control },
            disableClose: true,
            width: '800px',
        });

        dialogRef.afterClosed().subscribe(newControlVersion => {
            if (!newControlVersion) {
                return;
            }

            control.controlVersions.push(newControlVersion);
        });
    }

    addFile(controlVersion: ControlVersionType) {
        const dialogRef = this.dialog.open(AddSystemControlFileComponent, {
            data: { controlVersion },
            disableClose: true,
            width: '800px',
        });

        dialogRef.afterClosed().subscribe(newFile => {
            if (!newFile) {
                return;
            }

            controlVersion.files.push(newFile);
        });
    }
}
