import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LotType } from 'src/app/library/models/data.models';
import { ApiService } from 'src/app/library/services/api.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';

@Injectable()
export class TreeApiService extends ApiService {

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);
    }

    public getTreeByProject(id, filters): Observable<LotType[]> {
        filters.project = id;

        return this.httpGET(this.apiUrl + '/tree/project', this.getOptions(filters));
    }

    public downloadCSV(filters) {
        return this.httpGET(this.apiUrl + '/tree/project/download/csv', this.getOptions(filters));
    }
}
