import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { ControlType, SubSystemType, SystemType } from 'src/app/library/models/data.models';
import { SelectData } from 'src/app/library/models/global.models';
import { CompanyApiService } from 'src/app/shared/services/company-api.service';
import { ControlApiService } from 'src/app/tree/services/control-api.service';
import { ModelControlApiService } from 'src/app/tree/services/model-control-api.service';

interface ControlData {
    subSystem?: number;
    system?: number;
    modelControl: number;
    company?: number;
    name?: string;
    shortName?: string;
}

@Component({
    selector: 'app-tree-add-system-control',
    templateUrl: './add-system-control.component.html',
    styleUrls: ['../../../../shared/styles/dialog.component.css', './add-system-control.component.css']
})
export class AddSystemControlComponent {

    public companies: Observable<SelectData[]>;
    public error: string = null;
    public modelControls: Observable<SelectData[]>;
    public newControl = new FormGroup({
        company: new FormControl(''),
        modelControl: new FormControl('', Validators.required),
        name: new FormControl(''),
        shortName: new FormControl(''),
    });

    private system: SystemType;
    private subSystem: SubSystemType;

    constructor(
        public dialogRef: MatDialogRef<AddSystemControlComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { system: SystemType, subSystem: SubSystemType },
        private companyApiService: CompanyApiService,
        private controlApiService: ControlApiService,
        private modelControlApiService: ModelControlApiService
    ) {
        this.system = data.system;
        this.subSystem = data.subSystem;

        if (!this.system && !this.subSystem) {
            this.error = 'Système ou Sous-système manquant.';
        }

        this.companies = this.companyApiService.getList();

        const param: { subSystem?: number, system?: number } = {};
        if (this.system) {
            param.system = this.system.id;
        } else {
            param.subSystem = this.subSystem.id;
        }
        this.modelControls = this.modelControlApiService.getList(param);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save() {
        const data: ControlData = {
            modelControl: null,
        };

        if (this.system) {
            data.system = this.system.id;
        } else {
            data.subSystem = this.subSystem.id;
        }

        if (!this.newControl.value.modelControl) {
            this.error = 'Modèle du contrôle ne peut pas être vide.';
            return;
        }

        data.modelControl = this.newControl.value.modelControl;

        if (this.newControl.value.company) {
            data.company = this.newControl.value.company;
        }

        if (this.newControl.value.shortName) {
            data.shortName = this.newControl.value.shortName;
        }

        this.controlApiService
            .add(data)
            .subscribe((control: ControlType[]) => {
                this.dialogRef.close(control[0]);
            });
    }
}
