import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConnectionComponent } from './components/connection/connection.component';

@NgModule({
    declarations: [
        ConnectionComponent,
    ],
    imports: [
        SharedModule
    ],
    providers: [],
    exports: [
        ConnectionComponent,
    ]
})
export class AuthenticationModule { }
