import { Component, ViewChild } from '@angular/core';
import { MatDrawer, MatRadioChange } from '@angular/material';
import { Subject } from 'rxjs';
import { TreeToggleNodeData } from 'src/app/library/models/data.models';
import { TreeRightDrawerSubjectParameter } from '../../models/tree.models';

@Component({
    selector: 'app-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['../../styles/tree.global.css', './tree.component.css']
})
export class TreeComponent {

    static subjectOpenRightDrawer: Subject<TreeRightDrawerSubjectParameter>;
    static subjectToggleNode: Subject<TreeToggleNodeData>;
    static subjectRefreshTree: Subject<void>;

    public rightDrawerState = false;

    @ViewChild('rightDrawer', { static: false }) rightDrawer: MatDrawer;

    constructor() {
        TreeComponent.subjectToggleNode = new Subject();
        TreeComponent.subjectOpenRightDrawer = new Subject();
        TreeComponent.subjectRefreshTree = new Subject();

        TreeComponent.subjectOpenRightDrawer.subscribe((element: TreeRightDrawerSubjectParameter) => {
            this.openRightDrawer();
        });
    }

    static displayTree(event: any, treeId): void {
        TreeComponent.subjectToggleNode.next({
            depth: event.value,
            forceClose: true,
            state: true,
            target: treeId
        });
    }

    openRightDrawer() {
        this.rightDrawerState = true;
        this.rightDrawer.open();
    }
    closeRightDrawer() {
        this.rightDrawerState = false;
        this.rightDrawer.close();
    }
}
