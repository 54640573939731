import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SelectData } from 'src/app/library/models/global.models';
import { ApiService } from 'src/app/library/services/api.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';

@Injectable()
export class LotApiService extends ApiService {

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);
    }

    public getList(): Observable<SelectData[]> {
        return this.httpGET(this.apiUrl + '/lot/list', this.getOptions());
    }
}
