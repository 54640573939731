import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TableDataType } from 'src/app/library/models/global.models';
import { TableApiService } from 'src/app/library/services/table-api.service';

export class TableDataSource implements DataSource<any> {

    private countSubject = new BehaviorSubject<number>(0);
    private dataSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public countData$ = this.countSubject.asObservable();

    constructor(public tableApiService: TableApiService) { }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.countSubject.complete();
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

    loadData(sortColumn, sortDirection = 'asc', pageIndex = 0, pageSize = 5, search = '', filters = {}) {
        this.loadingSubject.next(true);

        this.tableApiService.getTableData(sortColumn, sortDirection.toUpperCase(), pageIndex, pageSize, search, filters)
            .pipe(
                catchError(() => of({ data: [], count: 0 })),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((res: TableDataType<any>) => {
                this.countSubject.next(res.count);
                this.dataSubject.next(res.data);
            });
    }
}
