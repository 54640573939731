import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { ControlType, ControlVersionType } from 'src/app/library/models/data.models';
import { SelectData } from 'src/app/library/models/global.models';
import { ControlStatusApiService } from 'src/app/tree/services/control-status-api.service';
import { ControlVersionApiService } from 'src/app/tree/services/control-version-api.service';

@Component({
    selector: 'app-tree-add-system-control-version',
    templateUrl: './add-system-control-version.component.html',
    styleUrls: ['../../../../shared/styles/dialog.component.css', './add-system-control-version.component.css']
})
export class AddSystemControlVersionComponent {

    public control: ControlType;
    public controlStatusList: Observable<SelectData[]>;
    public error: string = null;
    public newControlVersion = new FormGroup({
        controlStatus: new FormControl('', Validators.required),
        comment: new FormControl(''),
    });

    constructor(
        public dialogRef: MatDialogRef<AddSystemControlVersionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { control: ControlType },
        private controlStatusApiService: ControlStatusApiService,
        private controlVersionApiService: ControlVersionApiService,
    ) {
        this.control = data.control;
        this.controlStatusList = this.controlStatusApiService.getList();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save() {
        const data: { control: number, controlStatus: number, version?: string, comment?: string } = {
            control: this.control.id,
            controlStatus: null,
        };

        if (!this.newControlVersion.value.controlStatus) {
            this.error = 'Le status du contrôle ne peut pas être vide.';
            return;
        }

        data.controlStatus = this.newControlVersion.value.controlStatus;

        if (this.newControlVersion.value.comment) {
            data.comment = this.newControlVersion.value.comment;
        }

        this.controlVersionApiService
            .add(data)
            .subscribe((controlVersion: ControlVersionType[]) => {
                this.dialogRef.close(controlVersion[0]);
            });
    }
}
