import { Component, Input, OnInit } from '@angular/core';
// import * as ExcelJS from 'exceljs';
import { from, Observable, of } from 'rxjs';
import { concatMap, last } from 'rxjs/operators';

interface DownloadConfig {
    fileName: string;
    sheets: SheetType[];
    type: 'csv' | 'xls';
}

interface SheetType {
    autoFilter: {
        from: string;
        to: string;
    };
    columns: ColumnType[];
    data: {
        observable?: Observable<any[]>;
        value?: any[];
    };
    name: string;
    rows: {
        alignment: any;
        font: any;
        height: number;
    };
    views: any[];
}

interface ColumnType {
    header: string;
    key: string;
    style: any;
    width: number;
}

@Component({
    selector: 'app-download-excel',
    templateUrl: './download-excel.component.html',
    styleUrls: ['./download-excel.component.css']
})
export class DownloadExcelComponent implements OnInit {

    private workbook;

    @Input() config: DownloadConfig;

    constructor() { }

    ngOnInit(): void {
        // this.workbook = new ExcelJS.Workbook();

        this.workbook.creator = 'SmartDataLab';
        this.workbook.lastModifiedBy = 'SmartDataLab';
        this.workbook.created = new Date();
        this.workbook.modified = new Date();
        this.workbook.lastPrinted = new Date();

        this.config.sheets.forEach(sheet => {
            this.newSheet(sheet);
        });
    }

    download() {
        from(this.config.sheets).pipe(
            concatMap(
                (sheet) => {
                    if (sheet.data.value) {
                        return of(sheet.data.value);
                    }
                    return sheet.data.observable;
                },
                (sheet, data) => {
                    const worksheet = this.workbook.getWorksheet(sheet.name);
                    worksheet.addRows(data);
                }
            ),
            last()
        ).subscribe(() => {
            switch (this.config.type) {
                case 'csv':
                    break;
                case 'xls':
                default:
                    this.workbook.xlsx.writeBuffer().then((data: Blob) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        const url = window.URL.createObjectURL(blob);
                        const anchor = document.createElement('a');
                        anchor.href = url;
                        anchor.download = this.config.fileName;
                        anchor.click();
                        window.URL.revokeObjectURL(url);
                    });
                    break;
            }
        });
    }

    private newSheet(config: SheetType) {
        const worksheet = this.workbook.addWorksheet(config.name);
        worksheet.views = [
            { state: 'frozen', xSplit: 0, ySplit: 1 }
        ];

        worksheet.autoFilter = {
            from: 'A1',
            to: 'M1'
        };

        worksheet.columns = config.columns;

        const firstRow = worksheet.getRow(1);

        firstRow.alignment = config.rows.alignment
            ? config.rows.alignment
            : { vertical: 'middle', horizontal: 'center' };

        firstRow.font = config.rows.font
            ? config.rows.font
            : { name: 'New Times Roman', family: 4, size: 10, bold: true, color: { argb: 'FF000000' } };

        firstRow.height = config.rows.height
            ? config.rows.height
            : 20;
    }
}
