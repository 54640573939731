import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModelSystemType, SystemType } from 'src/app/library/models/data.models';
import { SelectData } from 'src/app/library/models/global.models';
import { LotApiService } from '../../services/lot-api.service';
import { SystemApiService } from '../../services/system-api.service';
import { ModelSystemApiService } from '../../services/model-system-api.service';

@Component({
    selector: 'app-tree-add-new-system',
    templateUrl: './add-new-system.component.html',
    styleUrls: ['../../../shared/styles/dialog.component.css', './add-new-system.component.css']
})
export class AddNewSystemComponent {

    public error = null;
    public lotId: number = null;
    public lots: SelectData[] = [];
    public modelSystems: SelectData[] = [];
    public newSystemForm = new FormGroup({
        modelSystem: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
    });

    private projectId: number;

    constructor(
        public dialogRef: MatDialogRef<AddNewSystemComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { lotId: number, modelSystemId: number, projectId: number },
        private lotApiService: LotApiService,
        private modelSystemApiService: ModelSystemApiService,
        private systemApiService: SystemApiService
    ) {
        this.lotId = data.lotId;
        this.newSystemForm.controls.modelSystem.setValue(data.modelSystemId);
        this.lotApiService
            .getList()
            .subscribe((lots: SelectData[]) => {
                this.lots = lots;

                if (this.lotId) {
                    this.onChangeLot({ value: this.lotId });
                }
            });
        this.projectId = data.projectId;
    }

    public save() {
        const data = {
            modelSystem: null,
            name: null,
            project: this.projectId
        };

        if (!this.newSystemForm.value.name) {
            this.error = 'Le nom ne peut pas être vide.';
            return;
        }

        if (!this.newSystemForm.value.modelSystem) {
            this.error = 'Le nom ne peut pas être vide.';
            return;
        }

        data.name = this.newSystemForm.value.name;
        data.modelSystem = this.newSystemForm.value.modelSystem;

        this.systemApiService
            .add(data)
            .subscribe((system: SystemType) => {
                this.dialogRef.close(system);
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onChangeLot(event) {
        this.modelSystemApiService.getList({ lot: event.value })
            .subscribe((modelSystems: SelectData[]) => {
                this.modelSystems = modelSystems;
            });
    }
}
