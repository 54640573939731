import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DownloadExcelComponent } from './components/download-excel/download-excel.component';
import { EditableCheckboxComponent } from './components/editable/editable-checkbox/editable-checkbox.component';
import { EditableInputTextComponent } from './components/editable/editable-input-text/editable-input-text.component';
import { EditableSelectComponent } from './components/editable/editable-select/editable-select.component';
import { EditableComponent } from './components/editable/editable.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { EditableService } from './services/editable.service';
import { MediaService } from './services/media.service';
import { UploadFileApiService } from './services/upload-file-api.service';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        DownloadExcelComponent,
        EditableComponent,
        EditableCheckboxComponent,
        EditableInputTextComponent,
        EditableSelectComponent,
        LoaderComponent,
        UploadFileComponent
    ],
    entryComponents: [
        ConfirmDialogComponent
    ],
    imports: [
        SharedModule
    ],
    providers: [
        EditableService,
        MediaService,
        UploadFileApiService
    ],
    exports: [
        DownloadExcelComponent,
        EditableComponent,
        LoaderComponent,
        UploadFileComponent
    ]
})
export class LibraryModule { }
