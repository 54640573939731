import { Component } from '@angular/core';
import { TableComponent } from 'src/app/library/components/table/table.component';
import { CompanyApiService } from '../../../shared/services/company-api.service';

@Component({
    selector: 'app-company-table',
    templateUrl: './company-table.component.html',
    styleUrls: ['../../../library/components/table/table.component.css', './company-table.component.css']
})
export class CompanyTableComponent extends TableComponent {

    public displayedColumns = ['name'];

    constructor(apiService: CompanyApiService) {
        super(apiService);
    }
}
