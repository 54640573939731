import { Component, Input, OnInit } from '@angular/core';
import { ModelSubSystemType } from 'src/app/library/models/data.models';

@Component({
    selector: 'app-tree-node-model-sub-system',
    templateUrl: './tree-node-model-sub-system.component.html',
    styleUrls: ['../../styles/tree.global.css', './tree-node-model-sub-system.component.css']
})
export class TreeNodeModelSubSystemComponent implements OnInit {

    public treeId: string;

    @Input() modelSubSystem: ModelSubSystemType;
    @Input() treeParents: string[];

    constructor() { }

    ngOnInit() {
        this.treeId = this.treeParents.join('-') + '-' + this.modelSubSystem.id;
    }

    getChildrenParent() {
        const tmpArray = this.treeParents.slice(0);
        tmpArray.push(this.treeId);

        return tmpArray;
    }
}
