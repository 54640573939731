import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild, Output } from '@angular/core';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NgxDropzoneComponent } from 'ngx-dropzone';
import { ApiService } from 'src/app/library/services/api.service';
import { UploadFileApiService } from '../../services/upload-file-api.service';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

    files: File[] = [];
    multiple = true;

    @Input() label = 'Faites glisser les fichiers ici.';
    @Input() maxFiles: number = null;
    @Input() service: ApiService;
    @Input() uploadButton = true;

    @Output() uploaded = new EventEmitter<{ file: File, fileName: string }>();

    @ViewChild('input', { static: true }) input: ElementRef;
    @ViewChild(NgxDropzoneComponent, { static: true }) dropzone: NgxDropzoneComponent;

    constructor(private uploadFileApiService: UploadFileApiService) { }

    ngOnInit() {
        this.multiple = this.maxFiles === null || this.maxFiles > 1;
    }

    onFilesAdded(files: File[]) {
        this.files = files;
    }

    uploadFiles(funcGenerateName: any = null) {
        const obs = from(this.files).pipe(
            map((file: File) => {
                let fileName = file.name;
                if (funcGenerateName) {
                    fileName = funcGenerateName(file.name);
                }
                return { file, fileName };
            }),
            switchMap(
                (data) => this.uploadFileApiService.generateS3UploadUrl(data.fileName),
                (data, res: { url: string }) => ({ file: data.file, fileName: data.fileName, url: res.url })
            ),
            switchMap(
                (data) => this.uploadFileApiService.uploadFileS3(data.file, data.url),
                (data, o) => ({ file: data.file, fileName: data.fileName })
            )
        );

        if (!this.uploadButton) {
            return obs;
        }

        obs.subscribe(data => this.uploaded.emit(data));
    }

    resetFiles() {
        this.files = [];
        this.dropzone.reset();
    }
}
