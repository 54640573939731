import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExportControlDone, ExportControlTodoSystem, ExportControlTodoSystemless, ProjectType } from 'src/app/library/models/data.models';
import { SelectData, TableDataType } from 'src/app/library/models/global.models';
import { MediaService } from 'src/app/library/services/media.service';
import { TableApiService } from 'src/app/library/services/table-api.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';

@Injectable()
export class ProjectApiService extends TableApiService {

    constructor(protected http: HttpClient, protected connectionService: ConnectionService) {
        super(http, connectionService);
    }

    public getTableData(sortColumn, sortOrder = 'asc', pageNumber = 0, pageSize = 5, search = '')
        : Observable<TableDataType<ProjectType>> {
        return this.httpGETTable(this.apiUrl + '/project/table', sortColumn, sortOrder, pageNumber, pageSize, search);
    }

    public get(id: number): Observable<ProjectType[]> {
        return this.httpGET(this.apiUrl + '/project', this.getOptions({ project: id }));
    }

    public add(data): Observable<any> {
        return this.httpPOST(this.apiUrl + '/project/add', data, this.getOptions());
    }

    public edit(data): Observable<any> {
        return this.httpPOST(this.apiUrl + '/project/edit', data, this.getOptions());
    }

    public getList(): Observable<SelectData[]> {
        return this.httpGET(this.apiUrl + '/project/list', this.getOptions());
    }

    public exportControlTodoSystemless(id: number): void {
        this.httpGET(this.apiUrl + '/export/control/todo/system-less', this.getOptions({ project: id }))
            .subscribe((res: ExportControlTodoSystemless[]) => {
                MediaService.downloadCSV(['Projet', 'Lot', 'Type_de_systeme', 'Nom_système', 'Control', 'no_control'], res, 'contrôles_prévus_hors_sous_système');
            });
    }

    public exportControlTodoSystem(id: number): void {
        this.httpGET(this.apiUrl + '/export/control/todo/system', this.getOptions({ project: id }))
            .subscribe((res: ExportControlTodoSystem[]) => {
                MediaService.downloadCSV(['Projet', 'Lot', 'Type_de_systeme', 'Nom_système', 'Type_Ss_syst', 'Ss_syst', 'Control'], res, 'contrôles_prévus_avec_sous_système');
            });
    }

    public exportControlDone(id: number): void {
        this.httpGET(this.apiUrl + '/export/control/done', this.getOptions({ project: id }))
            .subscribe((res: ExportControlDone[]) => {
                res.forEach(r => {
                    r['Date_transfert'] = `${r['Date_transfert'].substring(8, 10)}/${r['Date_transfert'].substring(5, 7)}/${r['Date_transfert'].substring(0, 4)}`;
                });

                MediaService.downloadCSV(['no_control', 'Version', 'Commentaire', 'Statut', 'Date_transfert', 'Nom_fichier'], res, 'contrôles_reçus');
            });
    }
}
