import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyApiService } from 'src/app/shared/services/company-api.service';
import { CompanyType } from 'src/app/library/models/data.models';

@Component({
    selector: 'app-company-show',
    templateUrl: './company-show.component.html',
    styleUrls: ['../../../shared/styles/show.component.css', './company-show.component.css']
})
export class CompanyShowComponent {

    public company: CompanyType;

    constructor(private route: ActivatedRoute, private companyApiService: CompanyApiService) {
        const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

        this.companyApiService.get(id)
            .subscribe((companies: CompanyType[]) => {
                this.company = companies[0];
            });
    }
}
