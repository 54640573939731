import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { EditableService } from '../../services/editable.service';
import { SelectData } from '../../models/global.models';

@Component({
    selector: 'app-editable',
    templateUrl: './editable.component.html',
    styleUrls: ['./editable.component.css']
})
export class EditableComponent {

    public open = false;

    @Input() data: SelectData[];
    @Input() dataAsync: Observable<SelectData[]>;
    @Input() label: string;
    @Input() name: string;
    @Input() pk: number;
    @Input() pkkey: string;
    @Input() text: string;
    @Input() type: string;
    @Input() url: string;
    @Input() value: string;

    @Output() textChange = new EventEmitter<any>();

    constructor(private editableService: EditableService) { }

    edit() {
        this.open = true;
    }

    save(data: { text: string, value: string }) {
        const self = this;

        this.editableService
            .editable(this.url, this.getData(data.value))
            .subscribe((response: any) => {
                if (response.success) {
                    self.text = data.text;
                    self.value = data.value;

                    self.textChange.emit({ name: this.name, value: self.value });
                }

                this.close();
            });
    }

    close() {
        this.open = false;
    }

    private getData(value) {
        return {
            [this.pkkey]: this.pk,
            [this.name]: value
        };
    }
}
