import { Component } from '@angular/core';
import { TableComponent } from 'src/app/library/components/table/table.component';
import { ProjectApiService } from '../../services/project-api.service';

@Component({
    selector: 'app-project-table',
    templateUrl: './project-table.component.html',
    styleUrls: ['../../../library/components/table/table.component.css', './project-table.component.css']
})
export class ProjectTableComponent extends TableComponent {

    public displayedColumns = ['name', 'shortName', 'action'];

    constructor(apiService: ProjectApiService) {
        super(apiService);
    }
}
